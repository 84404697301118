@tailwind components;
@tailwind utilities;

:root {
  --appbar-h: 64px;
}

html,
body {
  padding: 0 !important;
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

* {
  box-sizing: border-box !important;

  @apply border-gray-40 dark:border-gray-90;
}

/* stylelint-disable selector-id-pattern, selector-class-pattern */
body,
body > div:first-child,
div#__next,
div#__next > div {
  @apply h-full bg-bluegray-10 dark:bg-gray-110 dark:text-gray-40 !important;
}

.react-datepicker__month--selected,
.react-datepicker__day--selected {
  /* prettier-ignore */
  @apply text-white bg-orange-50 !important;
}

.react-datepicker__month-text--keyboard-selected:not(
    .react-datepicker__month--selected
  ) {
  background-color: unset !important;
  color: unset !important;
}

.react-datepicker__day--outside-month {
  visibility: hidden !important;
}

/* NOTE: Mui5ではheaderのカスタムが出来ないので非表示にする */
.MuiCalendarPicker-root.Custom .MuiPickersCalendarHeader-root {
  display: none !important;
}

.MuiCalendarPicker-root.Custom .MuiDayPicker-slideTransition {
  min-height: 200px;
  height: auto;
}

.MuiCalendarPicker-root.Custom
  .MuiPickersFadeTransitionGroup-root
  .MuiDayPicker-header {
  @apply flex items-start justify-start;
}

.MuiCalendarPicker-root.Custom
  .MuiPickersFadeTransitionGroup-root
  .MuiPickersDay-root,
.MuiCalendarPicker-root.Custom
  .MuiPickersFadeTransitionGroup-root
  .MuiDayPicker-header
  .MuiTypography-root {
  color: unset;
  background-color: unset;

  @apply text-sm font-normal;
}

.MuiCalendarPicker-root.Custom
  .MuiPickersFadeTransitionGroup-root
  .MuiPickersDay-root[aria-current~='date'] {
  @apply border-0 text-orange-50;
}

.MuiCalendarPicker-root.Custom
  .MuiPickersFadeTransitionGroup-root
  .MuiPickersDay-root[aria-selected~='true'] {
  @apply rounded bg-orange-50 text-white dark:text-gray-120;
}

.MuiMonthPicker-root.Custom .PrivatePickersMonth-root {
  @apply rounded;
}
